import {NgxLoggerLevel} from 'ngx-logger';

export const environment = {
  production: true,
  baseUrl: 'https://reports.transport.cn.ua',
  cardholderBaseUrl: 'https://api.transport.cn.ua/cardholder',
  cardholderBaseLogin: '',
  cardholderBasePassword: '',
  zabbix: {
    zabbixUrl: 'https://zabbix.transport.cn.ua/zabbix/api_jsonrpc.php',
    zabbixDefaultGroups: ['18', '19'],
    zabbixPrinterGroupName: '',
    octGroups: ['18'],
    cvbGroups: ['19'],
    key_Parameters: 'vfs.file.contents[/data/usr/bin/FarePlus/$FSYS$/FarePlus/ZABB,total]',
    key_ip: 'system.loc_ip',
    key_gps: null,
    key_reboot_oct: null,
    key_reboot_cvb: null,
    key_ping: 'agent.ping',
    key_uptime: 'system.uptime',
    key_event_close: null,
    key_event_upload: null,
    disablePrinter: true,
    disableGPS: true,
    depots: []
  },
  language: 'uk',
  logLevel: NgxLoggerLevel.ERROR,
  rowsInTable: 20,
  isDebugMode: false,
  countMonth: -12,
  countReportDaysDefore: 7,
  available_languages: ['uk', 'en'], // For ukrainian projects
  showLangIcon: false,
  version: '2.1.4',
  develop_company: 'SYMBOL TRANSPORT',
  develop_company_link: 'https://symboltransport.com/',
  // UAH RON
  report_currency: 'UAH',
  project: 'Чернігів'
};
